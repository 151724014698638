<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="expandable-composite"
    :class="{ expanded, 'clickable': !expanded }"
  >
    <div class="box-border">
      <div
        role="button"
        tabindex="0"
        :class="{ expanded }"
        class="label composite-header clickable"
        @keyup.enter="expanded = !expanded"
        @click="expanded = !expanded"
      >
        <div v-html="$t(composite.label, composite.interpolation)"></div>
        <base-icon
          v-if="!expanded"
          icon="caret-right"
        />
        <base-icon
          v-else
          icon="caret-down"
        />
      </div>
      <template v-for="child in composite.children">
        <form-element
          v-if="expanded"
          :key="child.field"
          v-model="values[child.field]"
          :defaultValue="defaultValue[child.field]"
          :error="getErrorsForField(child.field)"
          :disabled="disabled"
          :formElement="child"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExpandableComposite',
  props: {
    composite: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: [Array, Object],
    },
    defaultValue: {
      type: [Object, String, Number, Boolean],
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      expanded: this.hasErrors(),
      values: this.modelValue,
    };
  },
  watch: {
    values: {
      handler(updatedValues) {
        this.$emit('update:modelValue', updatedValues);
      },
      deep: true,
    },
  },
  methods: {
    hasErrors() {
      return this.error?.length > 0 && this.composite.children.some(c => this.getErrorsForField(c.field));
    },
    getErrorsForField(feField) {
      if (!this.error) {
        return undefined;
      }
      const associatedErrors = this.error.filter(e => e.context.field[0] === feField);
      return associatedErrors.length === 0 ? undefined : associatedErrors;
    },
  },
};
</script>
<style scoped lang="scss">
.box-border {
  justify-content: center;
  flex-direction: column;
  margin: 20px 0px;
  padding: 0 25px;
  transition: height 2s;
}
.composite-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.label {
    margin: 0;
    padding: 22px 0;
  }
  &.expanded {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}
</style>
