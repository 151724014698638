import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 14 }

import { computed, ref, onBeforeUnmount, onBeforeMount, PropType, inject } from 'vue';
import CmcBatchInput from '@/components/nextgen/sdk/CmcBatchInput.vue';
import CmcSshKeyInput from './CmcSshKeyInput.vue';
import CmcTextArea from '../inputs/CmcTextArea.vue';
import CmcRadioGroup from '../inputs/CmcRadioGroup.vue';
import CmcRowRepeatable from '../display/CmcRowRepeatable.vue';
import { useI18n } from 'vue-i18n';
import { useConfirmDialog } from '../overlays/useConfirmDialog';
import { StepProviderProps } from '../form/CmcStepProvider.vue';
import CmcSelect from '../inputs/select/CmcSelect.vue';
import CmcSelectMulti from '../inputs/select/CmcSelectMulti.vue';

const DEBOUNCE_TIMEOUT = 800;

type FormOption = {
  interpolation: any;
  type: string;
  name: string;
  is18n?: boolean;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: Object[];
  value: string;
  imageUrl?: string;
  icon?: string;
  titles?: Object[];
  options: FormOption[];
  group?: string;
  detail?: string;
  withDetailI18n?: boolean;
  color?: string;
  isDisabled?: boolean;
  tooltip?: string;
  isTooltipI18n?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcFormElement',
  props: {
  modelValue: {
    type: [Object, Date, String, Number, Boolean, Array] as PropType<object | Date | string | number | boolean | Array<any>>,
    required: false,
  },
  category: {
    type: [Object, Date, String, Number, Boolean, Array] as PropType<object | Date | string | number | boolean | Array<any>>,
    required: false,
  },
  selectedConfig: {
    type: Object,
    required: false,
  },
  formElement: {
    type: Object,
    required: true,
  },
  error: {
    type: [Array, Object] as PropType<Array<any> | object>,
  },
  disabled: {
    type: Boolean,
  },
  defaultValue: {
    type: [Object, String, Number, Boolean],
    required: false,
  },
},
  emits: ["update:modelValue", "update:category", "changeConfig", "change", "reload", "update:batch"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const props = __props;

const emit = __emit;

const { open } = useConfirmDialog();
const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props', null)

const debounce = ref<number | undefined>(undefined);
const valueOnFocus = ref('');

const selectOptions = computed(() => {
  const optionConv = (o: FormOption) => ({
    label: o.name,
    withLabelI18n: o.is18n,
    value: o.value,
    color: o.color ? o.color : 'light-gray',
    interpolation: o.interpolation,
    detail: o.detail,
    withDetailI18n: true,
    isDisabled: o.isDisabled,
    withTooltip: o.tooltip,
    withTooltipI18n: o.isTooltipI18n
  });
  
  return props.formElement.options.map((o: FormOption) => {
    if (o.type === 'grouped') {
      return {
        label: o.name,
        withLabelI18n: o.is18n,
        options: o.options.map(opt => optionConv(opt))
      };
    }
    return [optionConv(o)];
  }).reduce((acc: any[], g: any[]) => acc.concat(g), []);
});

const checkboxGroupModelValue = computed(() => props.modelValue == null ? props.modelValue : props.modelValue as string[]);
const checkboxGroupOptions = computed(() => props.formElement.options.map((o: FormOption) => ({
  id: o.value,
  value: o.value,
  label: o.name,
  withI18n: o.is18n,
})));

const radioGroupOptions = computed(() => props.formElement.options.map((o: FormOption) => ({
  id: o.value,
  value: o.value,
  label: o.name,
  withI18n: o.is18n,
  disabled: o.isDisabled,
  withTooltip: o.tooltip,
  withTooltipI18n: o.isTooltipI18n
})));

const alertColour = computed(() => {
  switch (props.formElement.alertType) {
    case "INFO": return 'blue';
    case "SUCCESS": return 'green';
    case "WARNING": return 'yellow';
    case "DANGER": return 'red';
  }
  return '';
});

const isRepeatableRow = computed(() => props.formElement.type === 'repeatable-row');
const isErrorPresent = computed(() => props.error !== undefined && props.error !== null && JSON.stringify(props.error) !== '{}');
const isCollapsible = computed(() => props.formElement.type === 'collapsible');
const isBatch = computed(() => props.formElement.type === 'batch');
const isSlider = computed(() => props.formElement.type === 'slider');
const isInput = computed(() => ['text', 'password', 'number'].includes(props.formElement.type));
const isSensitive = computed(() => props.formElement.sensitive);
const isSingleSelect = computed(() => props.formElement.type === 'select');
const isMultiSelect = computed(() => props.formElement.type === 'tags' || props.formElement.type === 'multi-select-checkbox');
const isTextArea = computed(() => ['textarea', 'codeEditor'].includes(props.formElement.type));
const isCodeEditor = computed(() => props.formElement.type === 'codeEditor');
const isRadio = computed(() => props.formElement.type === 'radio');

const singleSelectValue = computed(() => props.modelValue === undefined || props.modelValue === null ? props.modelValue : props.modelValue.toString());
const multiSelectValue = computed(() => (props.modelValue && typeof props.modelValue === 'string') ? props.modelValue.split(',') : props.modelValue || []);

const errorList = computed(() => {
  if (!props.error || JSON.stringify(props.error) === '{}') return [];
  const errorArray = Array.isArray(props.error) ? props.error : [props.error];
  errorArray.forEach(e => {
    if (e.context && !e.context.fieldPath) {
      e.context.fieldPath = Array.isArray(e.context.field) ? e.context.field.join('.') : e.context.field;
    }
  });
  return errorArray;
});

const elemLabel = computed(() => props.formElement.label);
const elemDescLabel = computed(() => props.formElement.descriptionLabel);
const elemDisabledDescriptionLabel = computed(() => props.formElement.disabledDescriptionLabel);
const elemPlaceholderLabel = computed(() => !props.disabled ? props.formElement.placeholderLabel : '');
const errorText = computed(() => errorList.value.map(err => t(err.context.labelKey, err.context)).join("\n"));
const askConfirmationOnValueChange = computed(() => stepProps && stepProps.highestVisitedStep > stepProps.step && stepProps.active);

function collapsibleValue(field: string) {
  if (props.modelValue && typeof props.modelValue === 'object') {
    const value = props.modelValue as Record<string, any>;
    if (field in value) return value[field];
  }
  return undefined;
}

function collapsibleError(field: string) {
  if (!props.error || JSON.stringify(props.error) === '{}') return [];
  return (props.error as Record<string, any>)[field];
}

function collapsibleValueChange(field: string, v: any) {
  const newValue = { ...props.modelValue as Record<string, any> };
  if (!v) {
    delete newValue[field];
  } else {
    newValue[field] = v;
  }
  emit('update:modelValue', newValue);
  emit('change', newValue);
}

function categoryChange(v: string) {
  emit('update:category', v);
}

function configChange(v: object) {
  emit('changeConfig', v);
  reloadSections(props.modelValue as string);
}

function inputChange(v: any, checkReload = true) {
  if (checkReload && shouldEmitReload(v)) {
    if (askConfirmationOnValueChange.value) {
      open({
        title: "plugin_operations.confirm_on_change_header",
        description: "plugin_operations.confirm_on_change_desc",
        confirmLabel: "plugin_operations.change",
        onConfirm: () => {
          emit('update:modelValue', v);
          reloadSections(v);
        },
        onCancel: () => {},
      });
    } else {
      emit('update:modelValue', v);
      reloadSections(v);
    }
  } else {
    emit('change', v);
    emit('update:modelValue', v);
  }
}

function batchOptionChange(v: Record<string, any>) {
  emit('update:batch', v);
  reloadSections({});
}

function doDebounce(func: Function, timeout = DEBOUNCE_TIMEOUT) {
  window.clearTimeout(debounce.value);
  debounce.value = window.setTimeout(() => {
    func();
    debounce.value = undefined;
  }, timeout);
}

function shouldEmitReload(value: any) {
  return props.formElement.reloadOnChange && ((value !== undefined && value !== null && value !== props.modelValue) ||
    ['checkbox', 'checkboxes', 'batch', 'listSelect'].includes(props.formElement.type) ||
    (props.formElement.type === 'select' && value === undefined));
}

function onInputFocus() {
  valueOnFocus.value = props.modelValue as string;
}

function onInputBlur() {
  if (!valueOnFocus.value && !props.modelValue) {
    return;
  }
  if (valueOnFocus.value !== props.modelValue && shouldEmitReload(props.modelValue)) {
    if (askConfirmationOnValueChange.value) {
      open({
        title: "plugin_operations.confirm_on_change_header",
        description: "plugin_operations.confirm_on_change_desc",
        confirmLabel: "plugin_operations.change",
        onConfirm: () => reloadSections(props.modelValue as string),
        onCancel: () => emit('update:modelValue', valueOnFocus.value),
      });
    } else {
      reloadSections(props.modelValue as string);
    }
  }
}

function reloadSections(v: any) {
  if (shouldEmitReload(v)) {
    const emitChange = () => emit('reload', {
      oldValue: props.modelValue,
      field: props.formElement.field,
      formElement: props.formElement,
      value: v,
      sections: props.formElement.sectionsToReload,
      selectedConfig: props.selectedConfig || {},
    });
    
    if ([isSlider.value, isCollapsible.value, isBatch.value].some(Boolean)) {
      doDebounce(emitChange);
    } else {
      emitChange();
    }
  }
  emit('change', v);
}

onBeforeUnmount(() => {
  if (!['message', 'confirmation', 'collapsible'].includes(props.formElement.type)) {
    emit('update:modelValue', undefined);
    emit('changeConfig', {});
    emit('update:category', '');
  }
});

onBeforeMount(() => {
  if (props.defaultValue && props.formElement.field && !['message', 'confirmation', 'collapsible'].includes(props.formElement.type)) {
    emit('update:modelValue', props.defaultValue);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_cmc_form_element = _resolveComponent("cmc-form-element", true)!
  const _component_cmc_accordion = _resolveComponent("cmc-accordion")!
  const _component_cmc_sensitive_text_input = _resolveComponent("cmc-sensitive-text-input")!
  const _component_cmc_text_input = _resolveComponent("cmc-text-input")!
  const _component_cmc_checkbox_group = _resolveComponent("cmc-checkbox-group")!
  const _component_cmc_checkbox = _resolveComponent("cmc-checkbox")!
  const _component_cmc_alert = _resolveComponent("cmc-alert")!
  const _component_cmc_sensitive_text_area = _resolveComponent("cmc-sensitive-text-area")!
  const _component_cmc_list_select_form_element = _resolveComponent("cmc-list-select-form-element")!
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (__props.formElement.type === 'collapsible')
    ? (_openBlock(), _createBlock(_component_cmc_accordion, {
        key: 0,
        text: __props.formElement.label,
        "with-I18n": "",
        "keep-open": isErrorPresent.value
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formElement.children, (fe) => {
            return (_openBlock(), _createElementBlock("div", {
              key: fe.field
            }, [
              (fe.type === 'sshKey')
                ? (_openBlock(), _createBlock(CmcSshKeyInput, {
                    key: 0,
                    "model-value": __props.modelValue,
                    formElement: fe,
                    "as-optional": !fe.required,
                    disabled: fe.disabled || __props.disabled,
                    errors: __props.error,
                    onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload', $event))),
                    "onUpdate:modelValue": inputChange
                  }, null, 8, ["model-value", "formElement", "as-optional", "disabled", "errors"]))
                : (_openBlock(), _createBlock(_component_cmc_form_element, {
                    key: 1,
                    "model-value": collapsibleValue(fe.field),
                    "default-value": __props.defaultValue[fe.field],
                    formElement: fe,
                    disabled: fe.disabled || __props.disabled,
                    error: collapsibleError(fe.field),
                    "onUpdate:modelValue": ($event: any) => (collapsibleValueChange(fe.field, $event)),
                    onReload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reload', $event))),
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event)))
                  }, null, 8, ["model-value", "default-value", "formElement", "disabled", "error", "onUpdate:modelValue"]))
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["text", "keep-open"]))
    : (_openBlock(), _createBlock(_component_cmc_block, { key: 1 }, {
        default: _withCtx(() => [
          (isInput.value && isSensitive.value)
            ? (_openBlock(), _createBlock(_component_cmc_sensitive_text_input, {
                key: 0,
                "obscure-initial-value": !__props.formElement.viewable,
                "model-value": __props.modelValue?.toString(),
                type: __props.formElement.type,
                label: elemLabel.value,
                "with-label-i18n": "",
                "with-tooltip": elemDescLabel.value,
                "with-tooltip-i18n": "",
                "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                "with-warning-tooltip-i18n": "",
                "with-error-text": errorText.value,
                placeholder: elemPlaceholderLabel.value,
                "with-placeholder-i18n": "",
                disabled: __props.formElement.disabled || __props.disabled,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = (v) => inputChange(v, false)),
                onFocus: onInputFocus,
                onBlur: onInputBlur
              }, null, 8, ["obscure-initial-value", "model-value", "type", "label", "with-tooltip", "with-warning-tooltip", "with-error-text", "placeholder", "disabled"]))
            : (isInput.value)
              ? (_openBlock(), _createBlock(_component_cmc_text_input, {
                  key: 1,
                  "model-value": __props.modelValue?.toString(),
                  type: __props.formElement.type,
                  label: elemLabel.value,
                  "as-optional": !__props.formElement.required,
                  "with-label-i18n": "",
                  "with-tooltip": elemDescLabel.value,
                  "with-tooltip-i18n": "",
                  "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                  "with-warning-tooltip-i18n": "",
                  "with-error-text": errorText.value,
                  placeholder: elemPlaceholderLabel.value,
                  "with-placeholder-i18n": "",
                  disabled: __props.formElement.disabled || __props.disabled,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = (v) => inputChange(v, false)),
                  onFocus: onInputFocus,
                  onBlur: onInputBlur
                }, null, 8, ["model-value", "type", "label", "as-optional", "with-tooltip", "with-warning-tooltip", "with-error-text", "placeholder", "disabled"]))
              : (isSingleSelect.value)
                ? (_openBlock(), _createBlock(CmcSelect, {
                    key: 2,
                    label: elemLabel.value,
                    "as-optional": !__props.formElement.required,
                    "with-label-i18n": "",
                    "with-tooltip": elemDescLabel.value,
                    "with-tooltip-i18n": "",
                    "model-value": singleSelectValue.value,
                    options: selectOptions.value,
                    "with-placeholder": __props.formElement.disabled || __props.disabled ? elemDisabledDescriptionLabel.value : elemPlaceholderLabel.value,
                    "with-placeholder-i18n": "",
                    "allow-empty": !__props.formElement.required,
                    disabled: __props.formElement.disabled || __props.disabled,
                    "with-error-text": errorText.value,
                    withEmptyLabel: __props.formElement.emptyLabel,
                    "with-empty-label-i18n": "",
                    withReadOnlyEmptyLabel: __props.formElement.readOnlyEmptyLabel,
                    "onUpdate:modelValue": inputChange
                  }, null, 8, ["label", "as-optional", "with-tooltip", "model-value", "options", "with-placeholder", "allow-empty", "disabled", "with-error-text", "withEmptyLabel", "withReadOnlyEmptyLabel"]))
                : (isMultiSelect.value)
                  ? (_openBlock(), _createBlock(CmcSelectMulti, {
                      key: 3,
                      "as-tag": __props.formElement.type === 'tags',
                      label: elemLabel.value,
                      "as-optional": !__props.formElement.required,
                      "with-label-i18n": "",
                      "with-tooltip": elemDescLabel.value,
                      "with-tooltip-i18n": "",
                      "model-value": multiSelectValue.value,
                      options: selectOptions.value,
                      maxNumSelections: __props.formElement.maxNumSelections,
                      "with-placeholder": __props.formElement.disabled || __props.disabled ? elemDisabledDescriptionLabel.value : elemPlaceholderLabel.value,
                      "with-placeholder-i18n": "",
                      "allow-new": __props.formElement.type === 'tags' && !__props.formElement.options?.length,
                      disabled: __props.formElement.disabled || __props.disabled,
                      "with-error-text": errorText.value,
                      withEmptyLabel: __props.formElement.emptyLabel,
                      "with-empty-label-i18n": "",
                      withReadOnlyEmptyLabel: __props.formElement.readOnlyEmptyLabel,
                      "onUpdate:modelValue": inputChange
                    }, null, 8, ["as-tag", "label", "as-optional", "with-tooltip", "model-value", "options", "maxNumSelections", "with-placeholder", "allow-new", "disabled", "with-error-text", "withEmptyLabel", "withReadOnlyEmptyLabel"]))
                  : (__props.formElement.type === 'checkboxes')
                    ? (_openBlock(), _createBlock(_component_cmc_checkbox_group, {
                        key: 4,
                        "model-value": checkboxGroupModelValue.value,
                        options: checkboxGroupOptions.value,
                        label: elemLabel.value,
                        "as-optional": !__props.formElement.required,
                        "with-label-i18n": "",
                        "with-tooltip": elemDescLabel.value,
                        "with-tooltip-i18n": "",
                        "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                        "with-warning-tooltip-i18n": "",
                        "with-error-text": errorText.value,
                        disabled: __props.formElement.disabled || __props.disabled,
                        "onUpdate:modelValue": inputChange
                      }, null, 8, ["model-value", "options", "label", "as-optional", "with-tooltip", "with-warning-tooltip", "with-error-text", "disabled"]))
                    : (__props.formElement.type === 'checkbox')
                      ? (_openBlock(), _createBlock(_component_cmc_checkbox, {
                          key: 5,
                          "model-value": new String(__props.modelValue).toLowerCase() === 'true',
                          label: elemLabel.value,
                          "with-label-i18n": "",
                          "with-tooltip": elemDescLabel.value,
                          "with-tooltip-i18n": "",
                          "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                          "with-warning-tooltip-i18n": "",
                          "with-error-text": errorText.value,
                          disabled: __props.formElement.disabled || __props.disabled,
                          "as-toggle": __props.formElement.asToggle,
                          reversed: __props.formElement.reversed,
                          "as-header": __props.formElement.asToggle,
                          heading: "h4",
                          "onUpdate:modelValue": inputChange
                        }, null, 8, ["model-value", "label", "with-tooltip", "with-warning-tooltip", "with-error-text", "disabled", "as-toggle", "reversed", "as-header"]))
                      : (__props.formElement.type === 'message')
                        ? (_openBlock(), _createBlock(_component_cmc_alert, {
                            key: 6,
                            text: __props.formElement.label,
                            "with-i18n": "",
                            "with-full-width": "",
                            "with-outline": false,
                            "as-warning": __props.formElement.alertType === 'WARNING',
                            color: alertColour.value
                          }, null, 8, ["text", "as-warning", "color"]))
                        : (isTextArea.value && !isCodeEditor.value && isSensitive.value)
                          ? (_openBlock(), _createBlock(_component_cmc_sensitive_text_area, {
                              key: 7,
                              "obscure-initial-value": !__props.formElement.viewable,
                              "model-value": __props.modelValue,
                              label: elemLabel.value,
                              "with-label-i18n": "",
                              withTooltip: elemDescLabel.value,
                              "with-tooltip-i18n": "",
                              "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                              "with-warning-tooltip-i18n": "",
                              "with-placeholder": elemPlaceholderLabel.value,
                              "with-placeholder-i18n": "",
                              "with-error-text": errorText.value,
                              disabled: __props.formElement.disabled || __props.disabled,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = (v) => inputChange(v, false)),
                              onFocus: onInputFocus,
                              onBlur: onInputBlur
                            }, null, 8, ["obscure-initial-value", "model-value", "label", "withTooltip", "with-warning-tooltip", "with-placeholder", "with-error-text", "disabled"]))
                          : (isTextArea.value)
                            ? (_openBlock(), _createBlock(CmcTextArea, {
                                key: 8,
                                "model-value": __props.modelValue,
                                label: elemLabel.value,
                                "as-optional": !__props.formElement.required,
                                "with-label-i18n": "",
                                withTooltip: elemDescLabel.value,
                                "with-tooltip-i18n": "",
                                "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                                "with-warning-tooltip-i18n": "",
                                "with-placeholder": elemPlaceholderLabel.value,
                                "with-placeholder-i18n": "",
                                "with-error-text": errorText.value,
                                disabled: __props.formElement.disabled || __props.disabled,
                                "as-code-editor": isCodeEditor.value,
                                language: __props.formElement.language,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = (v) => inputChange(v, false)),
                                onFocus: onInputFocus,
                                onBlur: onInputBlur
                              }, null, 8, ["model-value", "label", "as-optional", "withTooltip", "with-warning-tooltip", "with-placeholder", "with-error-text", "disabled", "as-code-editor", "language"]))
                            : (__props.formElement.type === 'listSelect')
                              ? (_openBlock(), _createBlock(_component_cmc_list_select_form_element, {
                                  key: 9,
                                  label: elemLabel.value,
                                  "with-tooltip": elemDescLabel.value,
                                  category: __props.category,
                                  modelValue: __props.modelValue,
                                  config: __props.selectedConfig,
                                  "form-element": __props.formElement,
                                  disabled: __props.formElement.disabled || __props.disabled,
                                  "onUpdate:modelValue": inputChange,
                                  "onUpdate:category": categoryChange,
                                  "onUpdate:config": configChange
                                }, null, 8, ["label", "with-tooltip", "category", "modelValue", "config", "form-element", "disabled"]))
                              : (__props.formElement.type === 'sshKey')
                                ? (_openBlock(), _createBlock(CmcSshKeyInput, {
                                    key: 10,
                                    modelValue: __props.modelValue as Record<string, any>,
                                    formElement: __props.formElement,
                                    disabled: __props.formElement.disabled || __props.disabled,
                                    errors: __props.error,
                                    "onUpdate:modelValue": inputChange
                                  }, null, 8, ["modelValue", "formElement", "disabled", "errors"]))
                                : (isBatch.value)
                                  ? (_openBlock(), _createBlock(CmcBatchInput, {
                                      key: 11,
                                      "model-value": __props.modelValue == null ? __props.modelValue : __props.modelValue.toString(),
                                      "form-element": __props.formElement,
                                      errors: __props.error,
                                      disabled: __props.formElement.disabled || __props.disabled,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = (v) => inputChange(v, false)),
                                      onFocus: onInputFocus,
                                      onBlur: onInputBlur,
                                      "onUpdate:batch": batchOptionChange
                                    }, null, 8, ["model-value", "form-element", "errors", "disabled"]))
                                  : (isRepeatableRow.value)
                                    ? (_openBlock(), _createBlock(CmcRowRepeatable, {
                                        key: 12,
                                        "model-value": __props.modelValue || [],
                                        formElement: __props.formElement.defaultFormElement,
                                        errors: __props.error || {},
                                        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('change', $event))),
                                        onReload: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('reload', $event))),
                                        "onUpdate:modelValue": inputChange
                                      }, null, 8, ["model-value", "formElement", "errors"]))
                                    : (isRadio.value)
                                      ? (_openBlock(), _createBlock(CmcRadioGroup, {
                                          key: 13,
                                          label: __props.formElement.label,
                                          "with-label-i18n": "",
                                          withTooltip: elemDescLabel.value,
                                          "with-tooltip-i18n": "",
                                          "with-warning-tooltip": __props.disabled ? elemDisabledDescriptionLabel.value : '',
                                          "with-warning-tooltip-i18n": "",
                                          options: radioGroupOptions.value,
                                          "model-value": __props.modelValue,
                                          "onUpdate:modelValue": inputChange
                                        }, null, 8, ["label", "withTooltip", "with-warning-tooltip", "options", "model-value"]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_1, " TODO: " + _toDisplayString(__props.formElement.type), 1))
        ]),
        _: 1
      }))
}
}

})