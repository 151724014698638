<template>
  <div class="tile-select">
    <base-tabs 
      light 
      :tabs="tabs" 
      :selected="selectedTab"
      @tabChange="selectedTab = $event.value" 
    />
    <div class="tile-option-container">
      <tile-option 
        v-for="t in tiles[selectedTab]" 
        :key="`${t.value}-${selectedTab}`" 
        :tile="t" 
        :modelValue="modelValue" 
        :imageResolver="imageResolver"
        @update:modelValue="$emit('update:modelValue', $event)" 
      />
    </div>
  </div>
</template>

<script>
import TileOption from './TileOption';

export default {
  name: 'TileSelect',
  components: { TileOption },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
    },
    isI18n: {
      type: Boolean,
      default: false,
    },
    imageResolver: {
      type: Function,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedTab: '',
    };
  },
  computed: {
    tiles() {
      return this.options.reduce((acc, opt) => {
        const grouped = opt.type === 'grouped';
        const group = grouped ? opt.name : null;
        let opts = acc[group] || [];
        if (grouped) {
          opts = opts.concat(opt.options);
        } else {
          opts.push(opt);
        }
        acc[group] = opts;
        return acc;
      }, {});
    },
    tabs() {
      return Object.values(this.options.filter(opt => opt.type === 'grouped')
        .reduce((acc, grp) => {
          acc[grp.name] = grp;
          acc[grp.name].label = acc[grp.name].name;
          acc[grp.name].value = acc[grp.name].name;
          return acc;
        }, {}));
    },
    hasTabs() {
      return this.tabs && this.tabs.length && this.tabs[0];
    },
  },
  mounted() {
    if (!this.modelValue) {
      let firstTile = this.tiles[this.tabs[0].name][0];
      if (firstTile.options?.length) {
        firstTile = firstTile.options[0];
      }
      this.$emit('update:modelValue', firstTile.value);
    }
    this.selectedTab = this.tabs[0].name;
  },
};
</script>


<style scoped lang="scss">

.tile-option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

</style>
